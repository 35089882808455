import React from 'react';

import { FaBars, FaTimes } from 'react-icons/fa';
import Navbar from './navbar';

export default class HamburgNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        }
    }
    openNav = () => {
        this.setState({ collapsed: false });
    }
    closeNav = () => {
        this.setState({ collapsed: true });
    }
    render() {
        return (
            <div className="hamburg">
                {this.state.collapsed ?
                    <button onClick={this.openNav} className="hamburg__icon" title="open menu"><FaBars /></button>
                    :
                    <div className="hamburg__nav">
                        <div className="hamburg__menu">
                            <button onClick={this.closeNav} className="hamburg__close" title="close menu"><FaTimes /></button>
                            <Navbar lang={this.props.lang} />
                        </div>
                    </div>}
            </div>
        )
    }
}