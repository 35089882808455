import React from 'react';
import { FaLanguage } from 'react-icons/fa';
import { Location } from '@reach/router';
import LangLink from './lang-link';
import PopupSelector from './popup-selector';

const languages = {
    "zh-tw": "中文",
    "en-au": "English"
}

export default class LangSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayPopup: false
        }
    }
    displayPopupOn = () => {
        this.setState({ displayPopup: true });
    }
    displayPopupOff = () => {
        this.setState({ displayPopup: false });
    }
    render() {
        let { lang } = this.props;
        lang = lang || "zh-tw"; //sets site to default lang if none given
        return (
            <Location>{({ location }) =>
                <div className="lang-select" title="Language Select" aria-label=" Language Select">
                    <button className="remove-button-styles lang-select__button" onClick={this.displayPopupOn}>
                        <span className="lang-select__icon align-center-fa"><FaLanguage /></span>
                        <span className="lang-select__text">{lang === "en-au" ? "語言" : "Language"}</span>
                    </button>
                    {this.state.displayPopup && <PopupSelector displayOff={() => this.displayPopupOff()}>
                        {Object.keys(languages).map(key =>
                            <LangLink key={key} lang={key} to={getSlug(location.pathname, "zh-tw")}>{languages[key]}</LangLink>
                        )}
                    </PopupSelector>}
                </div>
            }</Location>
        )
    }
}

const getSlug = (pathname, defaultLang) => {
    let slug;
    const firstPath = pathname.match(/^\/[^/]*/)[0];
    let foundLang = Object.keys(languages).find(key => firstPath === `/${key}`);
    if (foundLang === defaultLang) {
        slug = pathname;
    } else if (foundLang) {
        slug = pathname.slice(firstPath.length);
    } else {
        slug = pathname;
    }
    return slug;
}