import { useStaticQuery, graphql } from 'gatsby';

export const usePrismicMetaData = () => {
  const { nodes } = useStaticQuery(graphql`
        query {
            allPrismicMetaData {
                nodes {
                  lang
                  data {
                    company_name
                    company_description
                    homlan_birthday
                    viewtec_slogan
                    address
                    phone_number
                    fax_number
                    email
                    keywords {
                      keyword
                    }
                    homlan_url {
                      url
                    }
                    viewtec_url {
                      url
                    }
                    uniform_numbers
                  }
                }
              }
        }`).allPrismicMetaData;
  return nodes;
}

export default usePrismicMetaData;