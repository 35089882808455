import React from 'react';

const PopupSelector = ({ children, displayOff }) => {
    return (
        <button className="remove-button-styles popup-selector" onClick={() => displayOff()}>
            <div className="popup-selector__selections">
                {children}
            </div>
        </button>
    )
}

export default PopupSelector;