import { useStaticQuery, graphql } from 'gatsby';

export const useFooterData = () => {
    const { nodes } = useStaticQuery(graphql`
        query {
            allPrismicFooter {
                nodes {
                    data {
                        body {
                            ... on PrismicFooterBodyList {
                                slice_type
                                primary {
                                    title
                                    title_slug
                                }
                                items {
                                    item
                                    item_slug
                                }
                            }
                        }
                    }
                    lang
                }
            }
        }`).allPrismicFooter;
    return nodes;
}
