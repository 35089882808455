import { useStaticQuery, graphql } from 'gatsby';

export const useNavData = () => {
    const { nodes } = useStaticQuery(graphql`
        query {
            allPrismicNavbar {
                nodes {
                    lang
                    data {
                        body {
                            items {
                                section_slug
                                section_text
                            }
                            primary {
                                slug
                                text
                            }
                        }
                    }
                }
            }
        }`).allPrismicNavbar;
    return nodes;
}
