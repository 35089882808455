import React from "react"
import PropTypes from "prop-types"

import { useSiteMetaData } from "../hooks/use-site-meta-data";

import Header from "./header"
import Footer from "./footer"
import EmailForm from '../components/email-form';

import "../scss/main.scss"

const Layout = ({ children, lang, mainClassName }) => {
  const data = useSiteMetaData();
  lang = lang || data.defaultLang; //sets site to default lang if none given
  return (
    <>
      <Header siteTitle={data.title} lang={lang} />
      <main id="main" className={mainClassName}>
        {children}
      </main>
      <section id="email-form-section">
        <h2>聯絡我們! Get in touch!</h2>
        <EmailForm />
      </section>
      <Footer lang={lang} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
