import React from "react";
import { Link } from "gatsby";
import { useSiteMetaData } from "../hooks/use-site-meta-data";

//Adds a language indicator if not default language to url link ex: www.homlan.com/en-au
const LangLink = (props) => {
    const siteMetadata = useSiteMetaData();
    return (
        <Link
            {...props}
            to={`${(siteMetadata.defaultLang === props.lang) || !props.lang ? '' : '/' + props.lang}${props.to}`}
        />
    )
}

export default LangLink;