import React from "react";

import PropTypes from "prop-types";

import Navbar from "./navbar";
import HamburgNav from "./hamburg-nav";
import LangSelect from "../components/lang-select";
import LangLink from "../components/lang-link";

import HomlanLogo from "../images/homlan-logo.svg";
import ViewtecLogo from "../images/viewtec-logo-dark.svg";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    }
    this.headerRef = React.createRef();
  }

  handleScroll = (e) => {
    if (window.pageYOffset >= this.headerRef.current.clientHeight / 2) {
      this.setState({ collapsed: true });
    } else {
      this.setState({ collapsed: false });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { lang } = this.props;

    return (
      <div id="header-container">
        {/* {this.state.collapsed && <div id="header__placeholder"/>} */}
        <header
          id="header"
          className={`responsive-paddings--horizontal${this.state.collapsed ? ' header--collapsed' : ''}`}
          onScroll={this.handleScroll}
          ref={this.headerRef}>
          <HamburgNav lang={lang} />
          <div id="header__logo-section">
            <LangLink to="/" lang={lang} id="header__logo-section__container" className="noDecoration">
              <div id="header__logo-section__homlan">
                <img alt="homlan logo" id="header__logo-section__homlan-img" src={HomlanLogo} />
                {!this.state.collapsed && <div id="header__logo-section__homlan-slogan">豪聯國際 <span id="header__logo-section__homlan-slogan__date">Since 1987</span></div>}
              </div>
              <div id="header__logo-section__homlan">
                <img alt="viewtec logo" id="header__logo-section__viewtec-img" src={ViewtecLogo} />
                {!this.state.collapsed && <div id="header__logo-section__viewtec-slogan">Your Best Display Solution Supplier</div>}
              </div>
            </LangLink>
          </div>
          <div id="header__nav"><Navbar lang={lang} /></div>
          <LangSelect lang={lang} />
        </header>
      </div>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
