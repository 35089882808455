/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import { usePrismicMetaData } from "../hooks/use-prismic-meta-data"
import { useSiteMetaData } from "../hooks/use-site-meta-data"

import HomlanLogo from "../images/homlan-logo.jpg"

function SEO({ description, lang, meta, keywords, title, image }) {
  const siteMetadata = useSiteMetaData();
  lang = lang || useSiteMetaData.defaultLang;
  const metaData = usePrismicMetaData().find((node) => node.lang === lang);

  const metaDescription = description || meta.description || metaData.data.company_description;
  title = title || 'home';
  title = title.toLowerCase() === 'home' ?
    (lang === 'zh-tw' ? '豪聯國際股份有限公司' : 'Homlan International Corporation')
    : title;
  image = image || `${siteMetadata.siteURL}${HomlanLogo}`;

  const defaultKeywords = metaData.data.keywords.map(({ keyword }) => keyword);
  keywords.push(...defaultKeywords);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title === (lang === 'zh-tw' ? '豪聯國際股份有限公司' : 'Homlan International Corporation') ? title : `%s | ${(lang === 'zh-tw' ? '豪聯' : 'Homlan')}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: image,
        },
        {
          name: `google-site-verification`,
          content: `vo3BXOqHFAAOD-h23IkXStClqa3TzQTP7Ozu82v33LE`,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `zh-tw`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
