import React from 'react'

import { useFooterData } from '../hooks/use-footer-data';

import LangLink from '../components/lang-link';

const Footer = ({ lang }) => {
    const footerData = useFooterData().find(node => node.lang === lang).data;
    return (
        <footer id="footer" className="responsive-paddings">
            {footerData.body.map(({ primary, items }) => (
                <section key={primary.title} className="footer__section">
                    {(() => {
                        if (primary.title_slug) {
                            if (primary.title_slug.startsWith("http"))
                                return <h2 key={primary.title}><a href={primary.title_slug}>{primary.title}</a></h2>
                            else
                                return <h2 key={primary.title}><LangLink to={primary.title_slug} lang={lang}>{primary.title}</LangLink></h2>
                        } else
                            return <h2 key={primary.title}>{primary.title}</h2>
                    })()}
                    <ul>{items.map(({ item, item_slug }) => {
                        if (item_slug) {
                            if (item_slug.startsWith("http"))
                                return <li key={item}><a href={item_slug}>{item}</a></li>
                            else
                                return <li key={item}><LangLink to={item_slug} lang={lang}>{item}</LangLink></li>
                        } else
                            return <li key={item}>{item}</li>
                    })}</ul>
                </section>
            ))}
        </footer>
    )
}

export default Footer;