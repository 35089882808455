import React from 'react';

import { useSiteMetaData } from '../hooks/use-site-meta-data';
import { useNavData } from '../hooks/use-nav-data';

import LangLink from '../components/lang-link';

const Navbar = ({ lang }) => {
  const siteMetaData = useSiteMetaData();
  lang = lang || siteMetaData.defaultLang;
  const navData = useNavData().find(node => node.lang === lang);
  return (
    <nav id="nav">
      <ul id="nav__list" className="remove-list-styles">
        {
          navData.data.body.map(({ primary, items }, i) =>
            <li className="nav__list__item" key={`nav-item-${primary.slug}`}>
              <NavLink lang={lang} to={primary.slug} key={`navbutton${i}`}>{primary.text}</NavLink>
              {items.length > 0 && <NavDropDown
                items={items.map(({ section_slug, section_text }) => ({ text: section_text, slug: section_slug }))}
                lang={lang} />}
            </li >
          )
        }
      </ul>
    </nav>
  )
}

const NavLink = ({ lang, to, children }) => (
  <LangLink lang={lang} to={to} className="nav__list__item__link noDecoration" activeClassName="nav__list__item__link--active">
    {children}
  </LangLink>
)

const NavDropDown = ({ lang, items }) => (
  <ul className="nav__list__item__dropdown remove-list-styles">{items.map(({ text, slug }) => (
    <li className="nav__list__item__dropdown__item" key={`nav-dropdown-${slug}`}>
      <LangLink className="nav__list__item__dropdown__link" to={slug} lang={lang}>{text}</LangLink>
    </li>
  ))}</ul>
)


export default Navbar;