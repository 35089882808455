import React, { useState } from "react"

const EDITING = "EDITING"
const SENDING = "SENDING"
const FINISHED = "FINISHED"
const ERROR = "ERROR"

const EmailForm = () => {
  const [formStatus, setStatus] = useState(EDITING)

  const [name, setName] = useState("")
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [reason, setReason] = useState("")
  const submit = e => {
    e.preventDefault()
    setStatus(SENDING)
    submitEmail({ name, company, email, phone, reason })
      .then(res => {
        if (res.ok) {
          setStatus(FINISHED)
          console.log("success")
        } else {
          setStatus(ERROR)
          console.log(res)
        }
      })
      .catch(e => {
        setStatus(ERROR)
        console.log(e)
      })
  }
  return (
    <form method="post" name="emailform" id="email-form" onSubmit={submit}>
      {formStatus === ERROR && (
        <div id="email-form__error-msg">
          <p>An Error has occured</p>
          <p>發生了錯誤</p>
        </div>
      )}
      <fieldset disabled={formStatus === FINISHED || formStatus === SENDING}>
        <label htmlFor="email-form__name">名稱/Name: *</label>
        <input
          id="email-form__name"
          type="text"
          placeholder="Sandy"
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />
        <label htmlFor="email-form__company">公司/Company:</label>
        <input
          id="email-form__company"
          type="text"
          value={company}
          onChange={e => setCompany(e.target.value)}
          placeholder="Homlan"
        />
        <label htmlFor="email-form__email" id="email-form__email-label">
          電子信箱/Email: *
        </label>
        <input
          id="email-form__email"
          name="email"
          type="email"
          value={email}
          placeholder="sandy@homlan.com"
          onChange={e => setEmail(e.target.value)}
          required
        />
        <label htmlFor="email-form__phone" id="email-form__phone-label">
          電話/Phone:
        </label>
        <input
          id="email-form__phone"
          name="phone"
          type="tel"
          value={phone}
          placeholder="00000000000"
          onChange={e => setPhone(e.target.value)}
        />
        <label htmlFor="email-form__reason" id="email-form__email-label">
          問題或建議/Question or suggestion:{" "}
        </label>
        <textarea
          rows="3"
          placeholder="I would like a 17inch touch monitor."
          value={reason}
          onChange={e => setReason(e.target.value)}
        />
        <SubmitButton formStatus={formStatus} />
      </fieldset>
    </form>
  )
}

const SubmitButton = ({ formStatus }) => {
  let value
  switch (formStatus) {
    case EDITING:
      value = "發送/Submit"
      break
    case SENDING:
      value = "發送當中/Sending..."
      break
    case FINISHED:
      value = "成功Sent!"
      break
    default:
      value = "發送/Submit"
  }
  return (
    <input
      id="email-form__submit"
      className={formStatus}
      type="submit"
      value={value}
    />
  )
}

const submitEmail = async formData => {
  console.log("posting: ", formData)
  const response = await fetch(process.env.GATSBY_EMAIL_FORM_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
  return await response
}

export default EmailForm
